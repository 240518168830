.parent-home {
  color: white !important;

  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-top: 100px;
  align-items: center;
  width: fit-content;
  height: fit-content;
  padding: 0;
}

    
@media screen and (min-width: 992px) {

  .parent-home {
  color: white !important;
  display: flex;
  justify-content: space-between;
  margin-left: 30%;
  margin-top: 100px;
  align-items: center;
  width: fit-content;
  height: fit-content;
}
}

.home-info p {
  font-size: 14px;
  color: #ffbd39;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.home-info h4 {
  margin-top: -18px;
  font-size: 2rem;
  color: #ffbd39;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.home-info h2 {
  margin-top: -2rem;
  font-size: 1rem;
}

.btn-resume {
  background-color: #ffbd39 !important;
  border-color: #fba802 !important;
  border-radius: 0.5rem !important;
  color: black !important;
  font-size: small !important;
  font-weight: bold !important;
  height: auto !important;
  width: fit-content !important;
}

.btn-skills {
  background-color: transparent !important;
  border-radius: 0.5rem !important;
  border-color: white !important;
  color: white !important;
  height: auto !important;
  width: fit-content !important;
}


 img {
    max-width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
}
